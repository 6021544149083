import "./LoadingComponent.scss";
import React from 'react';

export default class LoadingComponent extends React.Component<any> {
    protected isSplashScreen = false;
    constructor(props) {
        super(props);
        if (props.isSplashScreen !== void 0) {
            this.isSplashScreen = props.isSplashScreen;
        }
    }

    render() {
        return (
            <section id="loading" className={this.isSplashScreen ? "splash-screen" : ""}>
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </section>
        )
    }

}