import React from 'react';
import PageComponent from "./../Component/PageComponent";
import Page from '../Fragment/Page';
import Fetcher from '../Helper/Fetcher';
import LoadingComponent from "./../Component/LoadingComponent";
import Footer from "../Fragment/Footer";

import "./Error404.scss";

export default class Error404 extends PageComponent {
  protected content = [];

  protected c(node) {
    return this.content[node];
  }

  render() {
    return (
      <Fetcher url={this.apiURL} language={this.dictionnary.getLocale()}>
        {({ data, isLoading, error, dataType }) => {
            let contentReady = false;
            if (data !== false) {
                contentReady = true;
                this.content = data.pages["error"];
            }
            return (
                <Page 
                  location={this.props.location} 
                  classes="scene error-404">
                {( 
                    error ? error.message
                    :(isLoading && !data ? (<LoadingComponent />) : (
                    contentReady &&
                    <>
                        <header>
                          <img src="/gfx/logo/white_logo.png" alt="Logo chateau de chimay" />
                        </header>
                        <div className="content">
                          <h1 dangerouslySetInnerHTML={{
                            __html: dataType === "adult" 
                            ? this.c("title") 
                            : this.c("title")
                          }} />
                          <div dangerouslySetInnerHTML={{
                              __html: dataType === "adult" 
                              ? this.c("content")
                              : this.c("contentForChildren")
                          }} />
                        </div>
                        <Footer
                          theme="white"
                          buttonLabel={this.tr("Retourner à la page d'accueil") } 
                          buttonPath={`/home`}
                          dictionnary={this.dictionnary}
                          hours={data.hours}
                          // showCountDown={false}
                      />
                    </>)
                    )
                )}
            </Page>)  
        }}
        </Fetcher>
    );
  }
}
