import './App.scss';
import React, { Component } from 'react';
import { 
  Route, 
  Switch, 
  BrowserRouter,
} from 'react-router-dom';
import { 
  CSSTransition, 
  TransitionGroup 
} from 'react-transition-group';
import Nav from './Fragment/Nav';
import routes from "./config/routes";
import * as localforage from 'localforage';
import Dictionnary from "./Helper/Dictionnary";
import Error404 from './Page/Error404';
import LoadingComponent from './Component/LoadingComponent';
import SplashScreen from './Page/SplashScreen';

export interface IApp {
  dictionnary: Dictionnary;
}

class App extends Component<IApp, any> {
  private supportsHistory = true;
  public currentPage = "";
  protected currentDepth = "";
  protected dictionnary;

  state = {
    showNavMenu: true,
    isNavOpen: false,
    choiceType: "adult",
    isLoading: false
  }

  constructor(props) {
    super(props);
    this.dictionnary = props.dictionnary;
    this.state.isLoading = true;
  }

  async componentWillMount() 
  {
    this.supportsHistory = 'pushState' in window.history;
    localforage.getItem("choiceType").then((value) => {
      if (value !== null && value !== this.state.choiceType) {
        let dictionnary = this.dictionnary;
        this.setState({
          choiceType: value,
          isLoading: !dictionnary.isReady()
        })
      } else {
        this.setState({ isLoading : !this.dictionnary.isReady() });
      }
    });
    
  }

  tickOut() 
    {
        setTimeout(() => {
            if (!this.dictionnary.isReady()) {
                this.tickOut();
            } else {
                this.setState({
                  isLoading: false
                })
            }
        }, 1000);
    }

  async componentDidMount()
  {
    if (!this.dictionnary.isReady()) {
      this.tickOut();
    }
  }

  showNavMenu (dataFromChild) {
    this.setState(dataFromChild);
  }

  // Triggered when user make a choice: Type / Language
  callback (data) 
  {
    if (data.component !== void 0) {
      switch (data.component) {
        case "ChoiceScreen":
          if (data.choiceType !== void 0) {
            localforage.setItem("choiceType", data.choiceType).then(() => {
              this.setState({
                choiceType: data.choiceType
              })
            });
          }
        break;
        case "Options":
          if (data.language !== void 0) {
            localforage.setItem("language", data.language).then(() => {
              window.location.reload();
            });
          }
      }
    }

    if (data.isNavOpen !== void 0) {
      
      this.setState({ isNavOpen : data.isNavOpen });
    }
  }

  render() {
    if (this.state.isLoading) {
      return <SplashScreen
      showNavMenuCallBack={this.showNavMenu.bind(this)} 
      callbackParent={ this.callback.bind(this) }
      />
    }
    
    return (<BrowserRouter forceRefresh={!this.supportsHistory}>
      <div>
        ({this.state.showNavMenu ? <Nav isOpen={this.state.isNavOpen} choiceType={this.state.choiceType} /> : "" })
        <main>
          <Route
            render={({ location }) => {
              const { pathname } = location;
              return (
                <TransitionGroup>
                  <CSSTransition 
                    key={pathname}
                    classNames="page"
                    timeout={{
                      enter: 500,
                      exit: 500,
                    }}
                  >
                    <Route
                      location={location}
                      key="main"
                      render={() => (
                        this.state.isLoading 
                        ? <LoadingComponent /> 
                        : (<Switch>
                          {routes.map((item,i) => {
                            item.label = this.dictionnary.getTranslation(item.label);
                            return ( 
                              <Route
                                key={`${i}a`}
                                path={item.pathname}
                                exact
                                render={(props) => <item.component 
                                  {...props} 
                                  showNavMenuCallBack={this.showNavMenu.bind(this)} 
                                  callbackParent={ this.callback.bind(this) }
                                  choiceType={this.state.choiceType}
                                  dictionnary={this.dictionnary}
                                />}
                              />);
                          })}
                          
                          <Route
                            render={(props) => <Error404 
                              {...props} 
                              showNavMenuCallBack={this.showNavMenu.bind(this)} 
                              callbackParent={ this.callback.bind(this) }
                              choiceType={this.state.choiceType}
                              dictionnary={this.dictionnary}
                            />}
                          />
                        </Switch>
                      ))}
                    />
                  </CSSTransition>
                </TransitionGroup>
              );
            }}
          />
        </main>
      </div>
    </BrowserRouter>)
  }
}

export default App;
