import React from 'react';
import PageComponent from "./../Component/PageComponent";
import LoadingComponent from "./../Component/LoadingComponent";
import Page from '../Fragment/Page';
import "./RoomObjectMap.scss";
// import Card from "../Fragment/Card";
// import tr from "./../Helper/Translation";
import Footer from "../Fragment/Footer";
import Fetcher from '../Helper/Fetcher';
// import ObjectTypes from '../Helper/ObjectTypes';
import * as $ from "jquery";
// import { settings } from 'cluster';
// import { shape } from 'prop-types';

require ("./../external/image-pro");

// eslint-disable-next-line no-use-before-define
var initMap = initMap || function() {};

export default class RoomObjectMap extends PageComponent {

    protected depthIndex = 3;
    protected room = { name:"", id: "", type: "", mapId: ""};
    protected content = [];
    // public pageState = {
    //     prev: true
    // }

    protected settings = {};
    protected actions = {};
    protected isLoading = true;

    protected roomType = "classic"; // type: classic or map    

    constructor(props)
    {
        super(props);
        this.tickOut();
    }

    manipulateRenderDataBeforeShowThem() {
        this.room = {
            name: this.props.match.params.type,
            id: this.props.match.params.roomid,
            type: this.props.match.params.type,
            mapId: this.props.match.params.mapId,
        };
    }

    protected c(node) {
        return this.content[node];
    }

    getMapping()
    {
        let mapping = this.content["mappings"][this.room.mapId];
        let settings = JSON.parse(mapping["mapping"]);
        let actions = {};
        $.each(settings.spots, function(k,v) {
            var set = v;
            if (set.actions !== void 0 && set.actions.link !== void 0) {
                set.actions = {
                    "click": "run-script",
                    "link": set.actions.link,
                    "script": ""
                };
            } else {
                set.actions = {
                    "click": "run-script",
                    "link": "#artwork-0",
                    "script": ""
                };
            }
            set.default_style = {
                "border_radius": 0,
                "background_opacity":0
            };
            set.tooltip_style = set.default_style;
            set.mouseover_style = set.default_style;
            settings.spots[k] = set;
            actions[set.title] = set.actions;
        });
        settings.layers = { enable_layers: false };
        settings.runtime = { is_fullscreen: false }
        settings.shapes = { pageload_animation: false }
        settings.fullscreen = { enable_fullscreen_mode : false }
        settings.tooltips = {"enable_tooltips":0 , fullscreen_tooltips: "never" };
        settings.zooming = {"enable_zoom_buttons": 1, "enable_zooming":1,"max_zoom":3,"enable_navigator":0,"hold_ctrl_to_zoom":0};
        settings.image = { "url": this.fetchApiUrl(mapping.picture) }
        settings.general.responsive = 0; 
        settings.general.center_image_map = 1; 
        const { width, height } = this.computeSizes(settings.general.naturalWidth, settings.general.naturalHeight);
        settings.general.width = width;
        settings.general.height = height;
        settings.general.center_image_map = 1;
        this.actions = actions;
        return settings;
    }

    private computeSizes(width, height) {
        let windowHeight = parseInt($(window).height());
        let windowWidth = parseInt($(window).width());
        let screenHeight = Math.round( windowHeight / 100 * 52);
        let screenWidth = windowWidth - 70;
        switch (true) {
            case height > width:
                return { 
                    width: Math.round(width / (height / screenHeight )),
                    height: screenHeight
                };
            case width > height:
                return { 
                    width: screenWidth,
                    height: Math.round(height / (width / screenWidth )),
                };
            default:
                return { 
                    width: windowWidth - 50,
                    height: screenHeight
                };
        }
    }

    renderContent()
    {
        this.settings = this.getMapping();
        return <div id="image-map-pro-container"></div>;
    }

    tickOut() 
    {
        setTimeout(() => {
            if (this.isLoading) {
                this.tickOut();
            } else {
                let { history } = this.props;
                const actions = this.actions;
                const room = this.room;
                $('#image-map-pro-container').imageMapPro(this.settings);
                $.imageMapProEventClickedShape = function(imageMapName, shapeTitle) {
                    if (actions[shapeTitle] !== void 0) {
                        let action = actions[shapeTitle];
                        let id = action.link.replace("#artwork-", "");
                        if (id !== "0" && window.location.href.indexOf("/portraits/map/") > -1) {
                            history.push({
                                pathname: `/room/${room.id}/${room.type}/${id}`,
                                state: { frommap: true, mapid: room.mapId, choiceType: "information" }
                            });
                        }
                    }
                }
            }
        }, 1000);
    }

    render() {
        this.manipulateRenderDataBeforeShowThem();
        return (
            <Fetcher language={this.dictionnary.getLocale()} url={this.apiURL}>
            {({ data, isLoading, error }) => {
                this.isLoading = isLoading;
                let contentReady = false;
                if (data !== false) {
                    contentReady = true;
                    this.content = data.rooms[this.room.id];
                }
                return (
                    <Page 
                        // location={this.props.location}
                        classes="scene room-object-map"
                    >

                    {( 
                        error ? error.message
                        :(isLoading && !data ? (<LoadingComponent />) : (
                        contentReady &&
                        <>
                            <header className="appear-fade-in">
                                <h1>{this.c("name")}</h1>
                                <h2>{ this.tr("Naviguez sur le mur via l'image ci-dessous et cliquez sur le portrait que vous voulez consulter") }</h2>
                            </header>
                            <section className="CardWrapper appear-fade-in">
                                {
                                    data !== false ? (
                                        this.renderContent()
                                    ) : ""
                                }
                            </section>
                            <Footer 
                                buttonLabel={this.tr("Retour à la salle") } 
                                buttonPath={`/room/${this.room.id}/portraits`}
                                dictionnary={this.dictionnary}
                                hours={data.hours}
                            />
                        </>)
                        )
                    )}
                </Page>)  
            }}
            </Fetcher>
        );
    }
}