import React from 'react';
import PageComponent from "./../Component/PageComponent";
import Page from '../Fragment/Page';
import "./LockScreen.scss";
import ReactCodeInput from "react-code-input";
import { Redirect} from 'react-router-dom';
import * as localforage from 'localforage';
import axios from "axios";
import LoadingComponent from './../Component/LoadingComponent';
import config from "../config";

export default class LockScreen extends PageComponent {
  
  protected depthIndex = 0;
  protected showNavMenu = false;
  public pageState = {
    prev: false
  }

  protected codePin;
  protected defaultCodePin = 6460;

  showNavMenuCallBack() {
    this.props.showNavMenuCallBack({ showNavMenu: false });
  }

  state = {
    hasAccess: false,
    pin: 0,
    isLoading: true,
    showError: false
  }

  constructor (props)
  {
    super(props);
    this.getCodePin();
    localforage.getItem("grantedAccess").then((value) => {
      
      this.setState({
        isLoading: false
      })
    });
  }

  async getCodePin() {
      await axios.get(config.API.uri + "/api/retrieve/code-pin")
        .then(result => {
            if (result.status === 200) {
              this.codePin = result.data.code;
            } else {
              this.codePin = this.defaultCodePin;    
            }
        })
        .catch(error => {
          this.codePin = this.defaultCodePin;
        })
    ;
  }

  handleSubmit(e)
  {
    e.preventDefault();
    if(this.state.pin === this.codePin) {
      this.setState({ showError: false });
      localforage.setItem("grantedAccess", true).then(() => {
        this.goToApp();
      });
    } else {
      this.setState({ showError: true });
    }
  }

  private goToApp()
  {
    this.setState({
      hasAccess: true
    })
  }

  onChange(e)
  {
    this.setState({
      pin: parseInt(e)
    });
  }

  render() {
    
    // if (this.state.isLoading)
    //   return <LoadingComponent />

    if (this.state.hasAccess === true) {
      return <Redirect to={{
          pathname: '/choice-screen',
          state: {
              prev: false
          },
          ...this.props
      }} 
      />
    }

    return (<Page 
      location={this.props.location}
      classes="scene lock-screen"
    >
    {this.state.isLoading ? <LoadingComponent /> :(
      <>
        <header>
          <img src="/gfx/logo/white_logo.png" alt="Logo chateau de chimay" />
        </header>
        <section>
        <h1>{this.tr("Avant de continuer, saisissez votre code")}</h1>
        { 
          this.state.showError 
          ? <p className="error">{this.tr("Code pin incorrect, veuillez re-essayer.")}</p>
          : "" 
        }
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="CodePinWrapper">
            <ReactCodeInput 
              onChange={this.onChange.bind(this)}
              type='number' 
              fields={4} 
              inputStyle={{
                marginRight:  '15px',
                MozAppearance: 'textfield',
                width: '15px',
                borderRadius: '0px',
                fontSize: '22px',
                height: '26px',
                // paddingLeft: '7px',
                paddingBottom: '5px',
                backgroundColor: 'transparent',
                color: '#fff',
                borderBottom: '3px solid rgba(255,255,255,0.2)'
              }}
              inputStyleInvalid={{
                color: 'red',
                borderBottom: '3px solid red'
              }}
            />
          </div>

          <div className="ButtonWrapper">
              <button className="Button">{this.tr("Valider")}</button>
          </div>
        </form>
        
        </section>
      </>
    )}

  </Page>)
  }
}