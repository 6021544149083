import React from 'react';
import PageComponent from "./../Component/PageComponent";
import Page from '../Fragment/Page';
import "./Home.scss";
import Footer from "../Fragment/Footer";
// import tr from "./../Helper/Translation";
// import * as localforage from 'localforage';
import Fetcher from '../Helper/Fetcher';
import LoadingComponent from "./../Component/LoadingComponent";

export default class Home extends PageComponent {
  
  protected depthIndex = 0;
  protected content = [];

  state = {
    choiceType: "information"
  }

  constructor(props) {
    super(props);
    this.state.choiceType = props.choiceType;
    
  }

  protected c(node) {
    return this.content[node];
  }

  render() {
    return this.renderApi();
  }

  renderApi() {
    return (
      <Fetcher url={this.apiURL} language={this.dictionnary.getLocale()}>
        {({ data, isLoading, error, dataType }) => {
            let contentReady = false;
            if (data !== false) {
                contentReady = true;
                this.content = data.pages["home"];
            }
            return (
                <Page 
                  location={this.props.location} 
                  classes="scene home">
                {( 
                    error ? error.message
                    :(isLoading && !data ? (<LoadingComponent />) : (
                    contentReady &&
                    <>
                        <header>
                          <img src="/gfx/logo/white_logo.png" alt="Logo chateau de chimay" />
                        </header>
                        <div className="content">
                          <h1 dangerouslySetInnerHTML={{
                            __html: dataType === "adult" 
                            ? this.c("title") 
                            : this.c("title")
                          }} />
                          <div dangerouslySetInnerHTML={{
                              __html: dataType === "adult" 
                              ? this.c("content")
                              : this.c("contentForChildren")
                          }} />
                        </div>
                        <Footer
                            theme="white"
                            buttonLabel={this.tr("Commencer la visite") } 
                            buttonPath={`/rooms`}
                            dictionnary={this.dictionnary}
                            hours={data.hours}
                        />
                    </>
                    )
                    )
                )}
            </Page>)  
        }}
        </Fetcher>
    );
  }
}