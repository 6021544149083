import React, { Component} from 'react';
// import Link from 'react-router-dom/Link';
// import NavLink from 'react-router-dom/NavLink';
import {NavLink} from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import routes from "../config/routes";
import './nav.scss';

export default class Nav extends Component<any> {

  state = {
    choiceType: "adult",
    isOpen: true
  }

  constructor(props) {
    super(props);
      this.state.choiceType= props.choiceType;
      this.state.isOpen = props.isOpen;
  }
  handleChangePage() {
    
  }

  render() {

    const { choiceType } = this.state;
    // let closeButton:HTMLButtonElement|null = document.querySelector('.bm-cross-button button');
    var isMenuOpen = function(state) {
      // if (closeButton !== null 
      //   && state.isOpen === true 
      //   && closeButton.offsetWidth > 0 
      //   && closeButton.offsetHeight > 0) {
      //   closeButton.click();
      // }
      return state.isOpen;
    };

    return (
      <nav className="nav">
        <Menu 
          isOpen={this.state.isOpen}
          onStateChange={ isMenuOpen }
          customBurgerIcon={ <img src="/gfx/icons/menu.svg" alt="Menu" /> }
        >
          <section className="logo">
            <img src="/gfx/logo/white_logo.png" alt="Logo"/>
          </section>
          <section className="items">
            { routes.map((item, key) => {
              if (item.showInMenu && (item.type === choiceType || item.type === "both")) {
                return (<NavLink 
                    key={key}
                    to={{
                      pathname: item.pathname,
                      state: item.state,
                      // prevDepthIndex: item.prevDepthIndex
                    }} 
                    className="menu-item"
                  >
                    <img src={item.icon} alt={item.label} /> <span>{item.label}</span>
                  </NavLink>
                )
              }
              return "";
            }) }
          </section>
        </Menu>
      </nav>
    );
  }
}