import React from 'react';
import PageComponent from "./../Component/PageComponent";
import Page from '../Fragment/Page';
import "./SplashScreen.scss";
import LoadingComponent from './../Component/LoadingComponent';

export default class SplashScreen extends PageComponent {
  
    protected depthIndex = 0;
    protected showNavMenu = false;
    // public pageState = {
    //   prev: true
    // }

    showNavMenuCallBack() {
        this.props.showNavMenuCallBack({ showNavMenu: false });
    }

  render() {

    return (<Page 
      classes="scene splash-screen"
    >
        <LoadingComponent />
    </Page>)
  }
}