import Home from '../Page/Home';
import LockScreen from '../Page/LockScreen';
import ChoiceScreen from '../Page/ChoiceScreen';
import Options from '../Page/Options';
// import About from '../Page/About';
import Rooms from '../Page/Rooms';
import Games from '../Page/Games';
import Room from '../Page/Room';
import RoomObjectIdentity from '../Page/RoomObjectIdentity';
import RoomObjectMap from '../Page/RoomObjectMap';
import RoomObjectType from '../Page/RoomObjectType';
import { exact } from 'prop-types';

const routes = [
    {
        label: ("Accueil"),
        pathname: "/home",
        state: { prev: false },
        component: Home,
        exact: exact,
        icon: "/gfx/icons/map.svg",
        showInMenu: true,
        prevDepthIndex: 1,
        type: "both"
    },
    {
        label: ("Salles"),
        pathname: "/rooms",
        state: { prev: false },
        component: Rooms,
        icon: "/gfx/icons/rooms.svg",
        showInMenu: true,
        prevDepthIndex: 1,
        type: "adult"
    },
    {
        label: ("Options"),
        pathname: "/options",
        state: { prev: false },
        component: Options,
        icon: "/gfx/icons/settings.svg",
        showInMenu: true,
        prevDepthIndex: 1,
        type: "both"
    },
    {
        label: ("Room"),
        pathname: "/room/:roomid",
        state: { prev: false },
        component: Room,
        icon: "",
        showInMenu: false,
        type: "adult"
    },
    {
        label: ("RoomObjectType"),
        pathname: "/room/:roomid/:type",
        state: { prev: false },
        component: RoomObjectType,
        icon: "",
        showInMenu: false,
        type: "adult"
    },
    {
        label: ("LockScreen"),
        pathname: "/",
        state: { prev: false },
        component: LockScreen,
        icon: "",
        showInMenu: false,
        type: "both"
    },
    {
        label: ("ChoiceScreen"),
        pathname: "/choice-screen",
        state: { prev: false },
        component: ChoiceScreen,
        icon: "",
        showInMenu: false,
        type: "both"
    },
    {
        label: ("RoomObjectMap"),
        pathname: "/room/:roomid/:type/map/:mapId",
        state: { prev: false },
        component: RoomObjectMap,
        icon: "",
        showInMenu: false,
        type: "adult"
    },
    {
        label: ("RoomObjectIdentity"),
        pathname: "/room/:roomid/:type/:objectid",
        state: { prev: false },
        component: RoomObjectIdentity,
        icon: "",
        showInMenu: false,
        type: "adult"
    },
    {
        label: ("RoomObjectIdentity"),
        pathname: "/room/:roomid/:type/:objectid/games",
        state: { prev: false },
        component: RoomObjectIdentity,
        icon: "",
        showInMenu: false,
        type: "adult",
        choiceType: "game"
    },
    {
        label: ("Jeux"),
        pathname: "/games",
        state: { prev: false },
        component: Games,
        icon: "/gfx/icons/rooms.svg",
        showInMenu: true,
        type: "both"
    }
];

export default routes;