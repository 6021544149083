import { Component } from 'react';
import config from '../config';
import * as localforage from 'localforage';

export default class PageComponent extends Component<any> {

    protected depthIndex = 0;
    protected prevDepthIndex = 1;
    protected name = "component";
    protected showNavMenu = true;
    protected dictionnary;

    // public pageState = {
    //     prev: false
    // }

    protected componentLoaded = false;

    protected apiURL;

    showNavMenuCallBack() {
        this.props.showNavMenuCallBack({showNavMenu: this.showNavMenu });
    }

    callbackToParent() {
        this.props.callbackParent({
            component: this.name,
            isNavOpen: false
        });
    }

    constructor(props) {
        super(props);
        this.dictionnary = props.dictionnary;
        this.name = this.constructor.name;
        this.apiURL = (props.url !== void 0) ?  config.API.uri + props.url : this.props.url;
        this.init(props);

        localforage.getItem("grantedAccess").then((value) => {
            this.componentLoaded = true;
            if (value === void 0 || value === null || value === false) {
                let { history } = this.props;
                if (history !== void 0) {
                    history.push({
                        pathname: '/'
                    });
                }
            }
          });

          this.showNavMenuCallBack();
          this.callbackToParent();
    }

    protected init(props) {

    }

    public fetchPicture(picturePath) {
        return this.fetchApiUrl(picturePath);
    }

    public fetchApiUrl(path) {
        return config.API.uri + path;
    }

    protected redefineDirectionPageState(direction)
    {
        
    }

    tr(slug) {
        return this.dictionnary.getTranslation(slug);
    }
}