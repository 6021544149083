import React from 'react';
import PageComponent from "./../Component/PageComponent";
import LoadingComponent from "./../Component/LoadingComponent";
import Page from '../Fragment/Page';
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import Card from "../Fragment/Card";
import "./Rooms.scss";
import Fetcher from '../Helper/Fetcher';
import * as $ from "jquery";
// import ReactSVG from 'react-svg';
import Map from '../Fragment/Map';

require("../external/rwimage");
require("jquery.panzoom");


export default class Rooms extends PageComponent {

    protected depthIndex = 1;
    protected mapAlreadyCentered = false;
    protected data = [];

    handleAreaChange(route) 
    {
        if (route !== false ) {
            let { history } = this.props;
            history.push({
                pathname: route,
                state: { prev: false }
            });
        }
    }

    constructor(props) {
        super(props);
        this.assignClickEventOnMap();
    }

    protected assignClickEventOnMap() 
    {
        $('body').on("click touchstart", ".panzoom-elements .link", (e) => {
            let data = this.data;
            let $elm = $(e.currentTarget);
            let route = false;
            alert($elm.attr("id"));
            switch($elm.attr("id")) {
                case "hall":
                    route = data["pages"] && data["pages"].map ? data["pages"].map.hall : false;
                break;
                case "weapons":
                    route = data["pages"] && data["pages"].map ? data["pages"].map.weaponsRoom : false;
                break;
                case "thierrybosquet":
                    route = data["pages"] && data["pages"].map ? data["pages"].map.thierryBosquetRoom : false;
                break;
                case "theater":
                    route = data["pages"] && data["pages"].map ? data["pages"].map.theater : false;
                break;
                case "portraits":
                    route = data["pages"] && data["pages"].map ? data["pages"].map.portraitsRoom : false;
                break;
            }
            if (route !== false) {
                let { history } = this.props;
                history.push({
                    pathname: route,
                    state: { prev: false }
                });
            }
        });
    }

    handleMapClick(e) 
    {
        // console.log(e.currentTarget);
    }
    

    handleTabChange(tabId) {
        if (tabId === "two" && this.mapAlreadyCentered === false) {
            this.mapAlreadyCentered = true;
            // $("#two").find("img").css('transform', 'translate3d(0px, 0px, 0px) scale(1)');
            // let $elem = $(".panzoom-elements").panzoom({
            //     contain: "invert",
            //     minScale: 1.25,
            //     maxScale: 5,
            //     rangeStep: 0.005,
            //     $zoomIn: $("#a-in"),
            //     $zoomOut: $("#a-out")
            // });
            // $elem.panzoom("zoom", 2);    
            $('img[usemap]').rwdImageMaps();
        }
    }

    render() {
        return (
            <Fetcher language={this.dictionnary.getLocale()} url={this.apiURL}>
              {({ data, isLoading, error }) => {
                const depthIndex = this.depthIndex;
                this.data = data;
                return (
                    <Page 
                        // location={this.props.location}
                        classes="scene rooms"
                    >

                {( 
                   error ? error.message
                        :(isLoading && !data ? (<LoadingComponent />) : (
                            <TabProvider defaultTab="one" onChange={(tabId) => { this.handleTabChange(tabId) }}>
                                <section className="my-tabs appear-fade-in">
                                    <TabList className="my-tablist">
                                        <Tab tabFor="one">{ this.tr("Liste") }</Tab>
                                        <Tab tabFor="two">{ this.tr("Carte") }</Tab>
                                    </TabList>
                                    <div className="wrapper">
                                        <TabPanel tabId="one" className="fullheight">
                                            <h1>{ this.tr("Château de Chimay") }</h1>
                                            <h2>{ this.tr("Liste des pièces à visiter") }</h2>
                                            <div className="CardWrapper">
                                            {
                                                data !== false ? (
                                                    Object.values(data.rooms).map((content:any, index) => {
                                                        return <Card 
                                                            key={index}
                                                            pathname={"/room/" + content["id"] }
                                                            picture={this.fetchPicture(content["picture"])}
                                                            title={content["name"]}
                                                            prevDepthIndex={depthIndex}
                                                            fade={false}
                                                            state={{prev:false}}
                                                        />
                                                    })
                                                ) : ""
                                            }
                                            </div>
                                        </TabPanel>
                                        <TabPanel tabId="two" className="fullheight">
                                            {/* <div className="actions">
                                                <a href="#in" id="a-in">+</a>
                                                <a href="#out" id="a-out">-</a>
                                            </div> */}
                                            <div className="panzoom-elements">
                                                {/* <img alt='Map' src='/gfx/map.jpg' useMap="#image-map" />
                                                <map name="image-map" id="image-map-wrapper">
                                                    <area target="" alt="" title="" onClick={(e) => {e.preventDefault(); this.handleAreaChange(data.pages && data.pages.map ? data.pages.map.hall : false)}} href="#room" coords="1187,1198,1154,1535,1863,1593,1878,1249" shape="poly"/>
                                                    <area target="" alt="" title="" onClick={(e) => {e.preventDefault(); this.handleAreaChange(data.pages && data.pages.map ? data.pages.map.weaponsRoom : false)}} href="#room" coords="753,1188,709,1626,1133,1629,1172,1209" shape="poly"/>
                                                </map> */}
                                                {/* <ReactSVG src="/gfx/map.svg" onClick={this.handleMapClick}/> */}
                                                <Map dictionnary={this.dictionnary} map={data["pages"] && data["pages"].map ? data["pages"].map : []} state={this.state} history={this.props.history} />
                                            </div>
                                        </TabPanel>
                                    </div>
                                </section>
                            </TabProvider>
                        )
                    ))}
                </Page>
                )      
              }}
            </Fetcher>
        );
  }
}