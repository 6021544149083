import React , { Component } from 'react';
import "./Picture.scss";

export interface IPicture {
    src: string;
    alt?: string;
    extraClassName?: string;
}

export default class Picture extends Component<IPicture, any> {

    static defaultProps = {
        alt: "picture",
        src: "/",
        extraClassName: ""
    }

    private checks = {};

    render() {
        let className = "Picture " + this.props.extraClassName;
        return (
            <section className={className} style={{
                backgroundImage: `url(${this.props.src})`
            }}>
                <img src={this.props.src} alt={this.props.alt} title={this.props.alt} />
            </section>
        );
    }
}
