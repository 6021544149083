// import { RouteProps } from 'react-router';
import React from 'react';
import axios from "axios";
import config from "../config";
import * as localforage from 'localforage';
import UrlParser from "../Helper/UrlParser";

interface IFetcher {
    url: string,
    language: string,
    children: any
}

class Fetcher extends React.Component<IFetcher> {

    static defaultProps = {
        url: config.API.uri + "/api/retrieve/content/all",
        language: "fr"
    }

    protected content;
    protected currentTime = 0;
    protected forceRefresh = false;

    state = {
        data: false,
        isLoading: true,
        error: null
    };

    constructor(props) {
        super(props);
        let date = new Date();
        this.currentTime = date.getTime();
        const URI = new UrlParser(window.location.href);
        const URIParams = URI.getAllUrlParams();
        if(URIParams["forcerefresh"] !== void 0) 
        {
            this.forceRefresh = true;
        }

        this.state = {
            data: false,
            isLoading: true,
            error: null,
        };

    }

  async componentDidMount() {
    this.setState({ isLoading: true });

    let dataType = await localforage.getItem("choiceType").then(value => {
        if (value !== null) {
            return value;
        } else {
            return "adult";
        }
    });

    this.setState({ dataType: dataType });

    let makeUpdate = await localforage.getItem("last-update").then((value:any) => {
        if (value === null || this.forceRefresh === true || parseInt(value) < this.currentTime - config.timeUpdate) {
            return true;
        }
        return false;
    });
    
    await localforage.getItem("content-"+this.props.language).then((value) => {
        if (value === null || config.env === "dev" || makeUpdate === true) {
            let content;
            axios.get(this.props.url, {
                params: {
                    language: this.props.language
                }
            })
                .then(result => {
                    content = result.data;
                    localforage.setItem("content-"+this.props.language, content).then(() => {
                        this.setState({
                            data: content,
                            isLoading: false
                        });
                    });
                    localforage.setItem("last-update", this.currentTime).then(() => {
                        return true;
                    });
                })
                .catch(error => this.setState({
                    error,
                    isLoading: false
                }))
            ;
        } else {
            this.setState({
                data: value,
                isLoading: false
            })
        }

    });
  }

  render() {
      return this.props.children(this.state);
  }
}

export default Fetcher;