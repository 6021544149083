import React from 'react';
import PageComponent from "./../Component/PageComponent";
import LoadingComponent from "./../Component/LoadingComponent";
import Page from '../Fragment/Page';
import "./Games.scss";
import Card from "../Fragment/Card";
// import tr from "./../Helper/Translation";
// import Footer from "../Fragment/Footer";
import Fetcher from '../Helper/Fetcher';
// import ObjectTypes from '../Helper/ObjectTypes';

export default class Games extends PageComponent {

    protected room = { name:"", id: "", type: ""};
    protected content = [];
    // public pageState = {
    //     prev: true
    // }

    protected c(node) {
        return this.content[node];
    }

    protected getGames(data) {
        let games: any[] = [];
        Object.values(data.rooms).map((item:any,index) => {
            const path = `/room/${item["id"]}/portraits/`;
            Object.values(item["artworks"].artwork).map((artwork:any, i) => {
                artwork["path"] = path+artwork["id"];
                games.push(artwork);
                return true;
            });
            return true;
        });

        return games;
    }

    render() {
        
        
        return (
            <Fetcher language={this.dictionnary.getLocale()} url={this.apiURL}>
            {({ data, isLoading, error }) => {
                let contentReady = false;
                // let content = this.content;
                if (data !== false) {
                    contentReady = true;
                    this.content = data;
                }
                const depthIndex = this.depthIndex;
                return (
                    <Page 
                        // location={this.props.location}
                        classes="scene games"
                    >

                    {( 
                        error ? error.message
                        :(isLoading && !data ? (<LoadingComponent />) : (
                        contentReady &&
                        <>
                            <header className="appear-fade-in">
                                <h1>{ this.tr("Jeux") }</h1>
                                <h2>{ this.tr("Liste des jeux") }</h2>
                            </header>
                            <section className="CardWrapper appear-fade-in">
                            {
                                data !== false ? (
                                    this.getGames(data).map((content, index) => {
                                        return <Card 
                                            key={index}
                                            pathname={content["path"] }
                                            picture={this.fetchPicture(content["picture"])}
                                            title={this.tr("Puzzle " + (index + 1) ) }
                                            prevDepthIndex={depthIndex}
                                            fade={false}
                                            state={{prev:false}}
                                            choiceType='game'
                                        />
                                    })
                                ) : ""
                            }
                            </section>
                        </>)
                        )
                    )}
                </Page>)  
            }}
            </Fetcher>
        );
    }
}