import React , { Component } from 'react';
import "./Footer.scss";
import {Link} from 'react-router-dom';
// import tr from "./../Helper/Translation";
import moment from "moment";

export interface IFooter {
    depthIndex?: number;
    buttonLabel: string;
    buttonPath: string;
    state?: object;
    theme?: string;
    showCountDown?: boolean;
    choiceType?:string;
    labels?:any;
    dictionnary:object;
    hours?:any
}

export default class Footer extends Component<IFooter, any> {

    static defaultProps = {
        depthIndex: 0,
        theme: "blue",
        showCountDown: true,
        // state: {
        //     prev: true
        // },
        labels: {
            next: "Prochaine projection à ",
            none: "Plus aucune séance disponible aujourd'hui"
        }
    }

    protected dictionnary;

    protected interval;
    protected hours;
    state = {
        nextSeance: "00:00",
        hasSeance: true
    }

    constructor(props) {
        super(props);
        this.dictionnary = props.dictionnary;
        console.log(props);
        if (props.hours !== void 0) {
            this.hours = props.hours.split(",");
        }
    }

    componentDidMount() {
        if (this.props.showCountDown && this.hours !== void 0) {
            let currentHour = moment().format('H');
            let alreadySet = false;
            this.hours.map(hourNode => {
                let node = hourNode.split(":");
                if(parseInt(currentHour) < parseInt(node[0]) && alreadySet === false) {
                    alreadySet = true;
                    this.setState({
                        nextSeance : hourNode,
                        hasSeance: true
                    })
                }
                return true;
            });

            if (alreadySet === false ) {
                this.setState({
                    hasSeance : false
                })
            }
        }
        // update every second
        // this.interval = setInterval(() => {
            // let moment().format('H:m'));
        // }, 1000);
    }

    componentWillUnmount() {
        // this.stop();
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
          value = '0' + value;
        }
        return value;
    }

    render() {
        
        return (
            <footer className={`Footer ${this.props.theme}`}>
                <div>
                    <p className="ButtonWrapper">
                        <Link 
                            to={{
                                pathname: this.props.buttonPath,
                                // nextDepthIndex: this.props.depthIndex,
                                state: {
                                    prev: true
                                },
                                ...this.props
                            }}
                            className="Button go-light box-shadow"
                            >
                            { this.props.buttonLabel }
                        </Link>
                    </p>
                    { 
                        this.props.showCountDown === true 
                        ? (<p className="TimerWrapper">
                            {this.state.hasSeance ?  `${this.props.labels.next} ${this.state.nextSeance}` : this.props.labels.none }
                        </p>)
                        : ('') 
                    }
                </div>
            </footer>
        );
    }
}
