import React from 'react';
import PageComponent from "./../Component/PageComponent";
import LoadingComponent from "./../Component/LoadingComponent";
import Page from '../Fragment/Page';
import "./RoomObjectType.scss";
import Card from "../Fragment/Card";
import Footer from "../Fragment/Footer";
import Fetcher from '../Helper/Fetcher';
import ObjectTypes from '../Helper/ObjectTypes';
import {Link} from 'react-router-dom';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

export default class RoomObjectType extends PageComponent {

    protected depthIndex = 3;
    protected room = { name:"", id: "", type: ""};
    protected content = [];
    // public pageState = {
    //     prev: true
    // }

    protected roomType = "classic"; // type: classic or map    

    manipulateRenderDataBeforeShowThem() {
        this.room = {
            name: this.props.match.params.type,
            id: this.props.match.params.roomid,
            type: this.props.match.params.type
        };
    }

    protected c(node) {
        return this.content[node];
    }


    protected getArtworks(type) {
        return this.content["artworks"][ObjectTypes[type]];
    }

    renderClassicType() 
    {
        const depthIndex = this.depthIndex;

        return Object.keys(this.getArtworks(this.room.type)).length > 0 ?
        (Object.values(this.getArtworks(this.room.type)).map((content:any, index) => {
            return <Card 
                key={index}
                pathname={`/room/${this.room.id}/${this.room.type}/${content["id"]}`}
                picture={this.fetchPicture(content["picture"])}
                title={content["name"]}
                prevDepthIndex={depthIndex}
                size="small"
                coloredBackground={false}
            />
        })): <p className="text-center nothing">{this.tr("Aucune oeuvre disponible pour le moment")}</p>;
    }

    onClickSliderItem()
    {
        let { history } = this.props;
                
        history.push({
            pathname: arguments[1].props["data-link"]
        });
    }

    renderMapType()
    {
        const handleOnDragStart = e => e.preventDefault(); 

        return Object.keys(this.content["mappings"]).length > 0 ?
        <>
            <AliceCarousel 
                mouseDragEnabled 
                responsive={{
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1100: {
                        items: 3
                    }
                }}
                buttonsDisabled={true}
            >
            {Object.values(this.content["mappings"]).map((content:any, index) => {
                return <div onDragStart={handleOnDragStart} className="item" style={{cursor:"pointer", maxHeight: "70vh", marginRight: 10, marginLeft: 10}} key={index} data-link={`/room/${this.room.id}/${this.room.type}/map/${content["id"]}`}>
                    <Link
                        to={{
                            pathname: `/room/${this.room.id}/${this.room.type}/map/${content["id"]}`,
                            state: this.state
                        }} 
                    ><img src={this.fetchPicture(content["picture"])} alt={content["id"]}/></Link>
                </div>
            })}
            </AliceCarousel>
        </>
        : <p className="text-center nothing">{this.tr("Aucun mapping disponible pour le moment")}</p>;
    }

    isPortraits()
    {
        return this.room.type === "portraits";
    }

    render() {
        this.manipulateRenderDataBeforeShowThem();
        
        let roomType = this.roomType;
        return (
            <Fetcher language={this.dictionnary.getLocale()} url={this.apiURL}>
            {({ data, isLoading, error }) => {
                let contentReady = false;
                
                if (data !== false) {
                    contentReady = true;
                    this.content = data.rooms[this.room.id];
                    //
                    if (this.content["type"] !== void 0)
                        roomType = this.content["type"];
                }
                
                return (
                    <Page 
                        // location={this.props.location}
                        classes="scene room-object-type"
                    >

                    {( 
                        error ? error.message
                        :(isLoading && !data ? (<LoadingComponent />) : (
                        contentReady &&
                        <>
                            <header className="appear-fade-in">
                                <h1>{this.c("name")}</h1>
                                <h2>{ 
                                    roomType === "map" && this.isPortraits()
                                    ? this.tr("Choisissez un des murs pour continuer votre visite.")
                                    : this.room.name 
                                }</h2>
                            </header>
                            <section className="CardWrapper appear-fade-in">
                                {
                                    data !== false ? (
                                        (roomType === "map" && this.isPortraits() ? this.renderMapType() : this.renderClassicType())
                                    ) : ""
                                }
                            </section>
                            <Footer 
                                buttonLabel={this.tr("Retour à la salle") } 
                                buttonPath={`/room/${this.room.id}`}
                                dictionnary={this.dictionnary}
                                hours={data.hours}
                            />
                        </>)
                        )
                    )}
                </Page>)  
            }}
            </Fetcher>
        );
    }
}