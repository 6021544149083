import React from 'react';
import PageComponent from "./../Component/PageComponent";
import LoadingComponent from "./../Component/LoadingComponent";
import Page from '../Fragment/Page';
import Footer from "../Fragment/Footer";
import "./RoomObjectIdentity.scss";
import { TabProvider, Tab, TabPanel, TabList } from 'react-web-tabs';
import Fetcher from '../Helper/Fetcher';
import ObjectTypes from '../Helper/ObjectTypes';
import Picture from "../Fragment/Picture";
import Plyr from 'react-plyr';
// import Puzzle from 'react-image-puzzle';
import Puzzle from "../Puzzle/component";
import Swal from 'sweetalert2';

export default class RoomObjectIdentity extends PageComponent {

    protected depthIndex = 4;
    protected content = [];
    // public pageState = {
    //     prev: true
    // }

    protected dataType = "adult";
    protected choiceType = "information";
    protected fromMap = false;
    protected mapId = null;
    protected isRendered = false;

    state = {
        puzzleIsCompleted: false,
        choiceType: "game"
    } 

    constructor(props) {
        super(props);
        let tooltipsContainer = document.querySelector('.imp-tooltips-container');
        if (tooltipsContainer !== void 0 && tooltipsContainer !== null) {
            if (tooltipsContainer.parentNode !== null) tooltipsContainer.parentNode.removeChild(tooltipsContainer);
        }
        
        this.choiceType = props.choiceType;
        if (props.location.choiceType !== void 0) {
            this.choiceType = props.location.choiceType;
        }
        if (props.history.location.state !== void 0) {
            let state = props.history.location.state;
            if (state.choiceType !== void 0) {
                this.state.choiceType = state.choiceType;
            }
            if ( state.frommap !== void 0 && state.frommap === true ) {
                this.fromMap = true;
                this.mapId = state.mapid;
            }
        }
    }

    protected room = { name:"", id: "", type: "", objectId: ""};
    
    protected c(node) {
        return this.content[node];
    }

    manipulateRenderDataBeforeShowThem() {
        this.room = {
            name: this.props.match.params.type,
            id: this.props.match.params.roomid,
            type: this.props.match.params.type,
            objectId: this.props.match.params.objectid
        };
    }

    renderInformation()
    {
        return <section className="ContentWrapper">
            <TabProvider defaultTab="one">
                <section className="my-tabs" data-video={this.c("hasVideo") === true ? "true" : "false" }>
                    <TabList className="my-tablist">
                        {this.c("hasVideo") === true ?(
                            <>
                                <Tab tabFor="one">{ this.tr("Photos") }</Tab>
                                <Tab tabFor="two">{ this.tr("Video") }</Tab>
                            </>
                        ): "" }
                    </TabList>
                    <div className="wrapper appear-fade-in">
                        <TabPanel tabId="one">
                            <Picture src={this.fetchPicture(this.c("picture"))} />
                        </TabPanel>
                        <TabPanel tabId="two">
                        <div>
                            <Plyr 
                                type="video" 
                                url={this.fetchApiUrl(this.c("video"))} 
                                controls={['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']}
                            />
                        </div>
                        </TabPanel>
                    </div>
                </section>
            </TabProvider>
            <section className="content">
                <div>
                    <h1>{ this.c("name") }</h1>
                    <div dangerouslySetInnerHTML={{__html: this.c( this.dataType === "adult" ? "description" : "descriptionForChildren")}}/>
                </div>
            </section>
            <section className="AudioWrapper" data-audio={this.c("hasAudio") ? "true" : "false" }>
                <div>
                    <Plyr 
                        type="audio" 
                        url={this.fetchApiUrl(this.c("audio"))} 
                        controls={['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']}
                    />
                </div>
            </section>
        </section>
    }

    renderPicture () {
        return <TabProvider defaultTab="one">
            <section className="my-tabs" data-video={this.c("hasVideo") === true ? "true" : "false" }>
                <TabList className="my-tablist">
                    {this.c("hasVideo") === true ?(
                        <>
                            <Tab tabFor="one">{ this.tr("Photos") }</Tab>
                            <Tab tabFor="two">{ this.tr("Video") }</Tab>
                        </>
                    ): "" }
                </TabList>
                <div className="wrapper appear-fade-in">
                    <TabPanel tabId="one">
                        <Picture src={this.fetchPicture(this.c("picture"))} />
                    </TabPanel>
                    <TabPanel tabId="two">
                    <div>
                        <Plyr 
                            type="video" 
                            url={this.fetchApiUrl(this.c("video"))} 
                            controls={['play-large', 'play', 'progress', 'current-time', 'captions', 'fullscreen']}
                        />
                    </div>
                    </TabPanel>
                </div>
            </section>
        </TabProvider>;
    }

    renderPuzzle () {
        return <header className="puzzleHolder">
            <div>
                <Puzzle 
                    image={this.fetchPicture(this.c("picture"))}
                    level={4}
                    onDone={() => {
                        this.setState({
                            puzzleIsCompleted: true
                        });
                        Swal.fire({
                            type: 'success',
                            title: this.tr('Félications'),
                            showConfirmButton: false,
                            timer: 3500
                        })
                    }}
                />
            </div>
        </header>;
    }

    renderGame() {
        const content = <><h1>{ this.c("name") }</h1><div dangerouslySetInnerHTML={{__html: this.c("descriptionForChildren")}}/></>;
        const guess = <><h1>???</h1>{ this.tr("Résoud le puzzle pour découvrir ce qui se cache derrière celui-ci.")}</>
        return (<section className="ContentWrapper">
            { this.state.puzzleIsCompleted ? this.renderPicture() : this.renderPuzzle() }
            <section className="content">
                <div>
                    { this.state.puzzleIsCompleted ? content : guess}
                </div>
            </section>
        </section>);
    }

    render() {
        this.manipulateRenderDataBeforeShowThem();
        return (
            <Fetcher language={this.dictionnary.getLocale()} url={this.apiURL}>
            {({ data, isLoading, error, dataType }) => {
                this.dataType = dataType;
                let contentReady = false;
                let classes = "scene room-object-identity";
                if (data !== false) {
                    contentReady = true;
                    this.content = data.rooms[this.room.id]["artworks"][ObjectTypes[this.room.type]][this.room.objectId];
                    classes += " " + data;
                }


                if (this.c("hasAudio") && this.choiceType !== "game") {
                    classes += " has-audio";
                }

                return (
                    <Page 
                        // location={this.props.location}
                        classes={classes}
                    >

                    {( 
                        error ? error.message
                        :(isLoading && !data ? (<LoadingComponent />) : (
                        contentReady && (this.state.choiceType !== "game" ? this.renderInformation() : this.renderGame())))
                    )}
                    <Footer
                        dictionnary={this.dictionnary}
                        showCountDown={false}
                        theme="white"
                        buttonLabel={ this.fromMap ? this.tr("Retour sur le mur") : this.tr("Retour à la liste") } 
                        buttonPath={
                            this.state.choiceType === "game" ? "/games" : (
                                this.fromMap 
                                ? `/room/${this.room.id}/${this.room.type}/map/${this.mapId}`
                                : `/room/${this.room.id}/${this.room.type}`
                            )
                        }
                    />
                </Page>)  
            }}
            </Fetcher>
        );
    }
}