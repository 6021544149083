import React , { Component } from 'react';
import {Link} from 'react-router-dom';
import './Card.scss';

export interface ICard {
    pathname: string;
    picture: string;
    title: string;
    state: any;
    prevDepthIndex?: number;
    size?: string;
    coveredBackground?:boolean;
    coloredBackground?:boolean;
    fade?:boolean;
    onClick?:void;
    isIcon?:boolean;
    choiceType?:string;
    showTitle?:boolean;
}

export default class Card extends Component<ICard, any> {

    static defaultProps = {
        title: "red",
        pathname: "/",
        state: { prev : false },
        prevDepthIndex: 1,
        size: "big",
        coveredBackground: true,
        coloredBackground: true,
        fade: false,
        isIcon: false,
        choiceType: "information",
        showTitle: true
    }

    onLinkClick(e:Event) {
        e.preventDefault();
    }

    render() {
        let className = "Card " 
                        + this.props.size + " " 
                        + (this.props.coveredBackground ? "covered-background" : "contained-background" ) + " "
                        + (this.props.coloredBackground ? "colored-background" : "uncolored-background" ) + " "
                        + (this.props.fade ? "fade " : "")
                        + (this.props.isIcon ? "is-icon " : "")
                    ;

        this.props.state.choiceType = this.props.choiceType;
        return (
            <section className={className}>
                <div className="picture" style={{
                    backgroundImage: `url(${this.props.picture})`
                }}>
                    <Link 
                        to={{
                            pathname: this.props.pathname,
                            // prevDepthIndex: this.props.prevDepthIndex,
                            state: this.props.state
                        }}
                        >
                        <img src={this.props.picture} alt={this.props.title} />
                    </Link>
                </div>
                { 
                    this.props.showTitle === true ?
                    <div className="title">
                    <Link 
                        to={{
                            pathname: this.props.pathname,
                            // prevDepthIndex: this.props.prevDepthIndex,
                            state: this.props.state
                        }} 
                        >
                        {this.props.title}
                    </Link></div>
                    : "" 
                }
            </section>
        );
    }
}
