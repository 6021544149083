import React from 'react';
import PageComponent from "./../Component/PageComponent";
import Page from '../Fragment/Page';
import "./Options.scss";
import Footer from "../Fragment/Footer";
// import tr from "./../Helper/Translation";
// import Dictionnary from '../Helper/Dictionnary';
// import { lang } from 'moment';
import * as localforage from 'localforage';
import LoadingComponent from "./../Component/LoadingComponent";

export default class Options extends PageComponent {
  
  protected depthIndex = 0;

  public state = {
    isLoading: true
  }

  protected dataType;

  handleChangeLanguage(e)
  {
    localforage.setItem("language", e.currentTarget.dataset.lang).then(() => {
      window.location.reload();
    });
  }
  
  handleChangeDataType(e)
  {
    this.dataType = e.currentTarget.dataset.type;
    localforage.setItem("choiceType", e.currentTarget.dataset.type).then(() => {
      this.setState({ dataType: this.dataType });
    });
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.dataType = await localforage.getItem("choiceType").then(value => {
        if (value !== null) {
            return value;
        } else {
            return "adult";
        }
    });

    this.setState({isLoading : false });
  }

  render() {
    let currentLanguage = this.dictionnary.getLocale();
    if (this.state.isLoading)
      return <LoadingComponent />;
    return (<Page 
      location={this.props.location} 
      classes="scene options">
    <header>
      <img src="/gfx/logo/white_logo.png" alt="Logo chateau de chimay" />
      <h1>{this.tr("Options")}</h1>
      <div className="content">
        <h2>{this.tr("Choisissez votre langue")}</h2>
        <p className="Buttons">
          {
            this.dictionnary.getLocales().map((item,i) => {
              let className = ["Button outline"];
              if (item === currentLanguage) className.push("selected");
              return (<a data-lang={item.toLowerCase()} href={"#"+item.toLowerCase()} onClick={this.handleChangeLanguage.bind(this)} className={className.join(" ")} key={item}>{this.tr(item) }</a>);
            })
          }
        </p>

        <h2>{this.tr("Choisissez votre type de contenu")}</h2>
        <p className="Buttons">
          {
            ["Adult", "Child"].map((item,i) => {
              let className = ["Button outline"];
              if (item.toLowerCase() === this.dataType) className.push("selected");
              return (<a data-type={item.toLowerCase()} href={"#"+item.toLowerCase()} onClick={this.handleChangeDataType.bind(this)} className={className.join(" ")} key={item}>{this.tr("choiceType." + item)}</a>);
            })
          }
        </p>
      </div>
    </header>
    <Footer
        theme="white"
        buttonLabel={this.tr("Retourner à la page d'accueil") } 
        buttonPath={`/home`}
        showCountDown={false}
        dictionnary={this.dictionnary}
    />
  </Page>)
  }
}