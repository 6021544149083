import React from 'react';
import PageComponent from "./../Component/PageComponent";
import Page from '../Fragment/Page';
import "./ChoiceScreen.scss";
// import ReactCodeInput from "react-code-input";
// import tr from "./../Helper/Translation";
import { Link} from 'react-router-dom';
import * as localforage from 'localforage';
// import LoadingComponent from './../Component/LoadingComponent';

export default class ChoiceScreen extends PageComponent {
  
  protected depthIndex = 0;
  protected showNavMenu = false;
//   public pageState = {
//     prev: false
//   }

  state = {
    toHome: false,
    pin: 0,
    isLoading: true
  }

    showNavMenuCallBack() {
        this.props.showNavMenuCallBack({ showNavMenu: false });
    }

    constructor (props)
    {
        super(props);
        localforage.getItem("grantedAccess").then((value) => {
            this.componentLoaded = true;
            if (value === void 0 || value === null || value === false) {
                let { history } = this.props;
                
                history.push({
                    pathname: '/'
                });
            }   
        });
    }
    

    async handleChangeType(type, route)
    {
        let { history } = this.props;
        localforage.setItem("choiceType", type).then(() => {
            history.push({
                pathname: route,
                state: { prev: false }
            });
        });
    }

    render() {

        return (<Page 
        // location={{"state":this.pageState}}
        location={this.props.location}
        classes="scene choice-screen"
        >
            <header>
            <img src="/gfx/logo/white_logo.png" alt="Logo chateau de chimay" />
            </header>
            <section>
                <h1>{this.tr("Qui êtes-vous ?")}</h1>
                <div className="choices">
                    <Link 
                        to={{
                            // pathname: "/home",
                            // prevDepthIndex: this.props.prevDepthIndex + 1,
                            state: false
                        }}
                        className="Button"
                        onClick={this.handleChangeType.bind(this, "adult", "/home")}
                        >
                        {this.tr("Je suis un adulte")}
                    </Link>
                    <Link 
                        to={{
                            // pathname: "/games",
                            // prevDepthIndex: this.props.prevDepthIndex + 1,
                            state: false
                        }}
                        onClick={this.handleChangeType.bind(this, "child", "/games")}
                        className="Button outline"
                        >
                        {this.tr("Je suis un enfant")}
                    </Link>
                </div>
            </section>
    </Page>)
    }
}