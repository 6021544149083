import React from 'react';
import Page from '../Fragment/Page';
import PageComponent from './../Component/PageComponent';
import LoadingComponent from './../Component/LoadingComponent';
// import Link from 'react-router-dom/Link';
import "./Room.scss";
// import tr from "./../Helper/Translation";
import Card from "../Fragment/Card";
import Picture from "../Fragment/Picture";
import Footer from "../Fragment/Footer";
import Fetcher from '../Helper/Fetcher';
import { Redirect} from 'react-router-dom';

export default class Room extends PageComponent {
    protected depthIndex = 2;
    protected content = [];
    // public pageState = {
    //     prev: true
    // }

    protected routeParams;

    protected init() {
        
    }

    protected c(node) {
        return this.content[node];
    }

    render() {
        // const depthIndex = this.depthIndex;
        const roomid = this.props.match.params.roomid;
        return (<Fetcher language={this.dictionnary.getLocale()} url={this.apiURL}>
              {({ data, isLoading, error, dataType }) => {
                let contentReady = false;
                // let content = this.content;
                if (data !== false) {
                    contentReady = true;
                    if (data.rooms[roomid] === void 0) {
                        return <Redirect to={{
                            pathname: '/not-found',
                            state: {
                                prev: false
                            },
                            ...this.props
                        }} 
                        />
                    }
                    this.content = data.rooms[roomid];
                }
                const depthIndex = this.depthIndex;
                return (
                    <Page 
                        // location={this.props.location}
                        classes="scene room"
                    >

                    {( 
                        error ? error.message
                        :(isLoading && !data ? (<LoadingComponent />) : (
                        contentReady &&
                        <>
                            <header className="appear-fade-in">
                                <Picture src={this.fetchPicture(this.c("picture"))} />
                                <h1>{this.c("name")}</h1>
                            </header>
                            <section className="appear-fade-in">
                                <div className="introduction">
                                    <div dangerouslySetInnerHTML={{__html: this.c(dataType === "adult" ? "description" : "descriptionForChildren")}}/>
                                </div>
                                <div className="CardWrapper">
                                    { this.c("hasObjects") === false ? "" :
                                        <Card 
                                            pathname={`/room/${roomid}/objects`}
                                            picture="/gfx/icons/objects.svg"
                                            title={this.tr("Objets")}
                                            prevDepthIndex={depthIndex}
                                            size="tiny"
                                            state={{prev:true}}
                                            coloredBackground={false}
                                            coveredBackground={false}
                                            isIcon={true}
                                        /> 
                                    }
                                    { this.c("hasPortraits") === false ? "" :
                                        <Card 
                                            pathname={`/room/${roomid}/portraits`}
                                            picture="/gfx/icons/portraits.svg"
                                            title={this.tr("Portraits")}
                                            prevDepthIndex={depthIndex}
                                            size="tiny"
                                            coloredBackground={false}
                                            coveredBackground={false}
                                            isIcon={true}
                                        />
                                    }
                                    { this.c("hasDecorations") === false ? "" :
                                        <Card 
                                            pathname={`/room/${roomid}/decorations`}
                                            picture="/gfx/icons/decorations.svg"
                                            title={this.tr("Décorations")}
                                            prevDepthIndex={depthIndex}
                                            size="tiny"
                                            coloredBackground={false}
                                            coveredBackground={false}
                                            isIcon={true}
                                        />
                                    }
                                </div>
                            </section>
                            <Footer 
                                hours={data.hours}
                                buttonLabel={this.tr("Retour à la liste") } 
                                buttonPath={`/rooms`}
                                dictionnary={this.dictionnary}
                            />
                        </>)
                        )
                    )}
                </Page>)  
            }}
        </Fetcher>)
  }
}