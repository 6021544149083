import classNames from 'classnames';
import React , { Component } from 'react';
import "./Page.scss";
// import axios from "axios";
import config from '../config';

export interface IPage {
  children: any;
  classes?: string;
  chidlrenClasses?: string;
  color?: string;
  background?: string;
  location?: any;
  url?: string;
}

export default class Page extends Component<IPage, any> {

  static defaultProps = {
    color: '#223',
    background: '#fff',
    classes: "",
    chidlrenClasses: "",
    state: { prev: false, prevDepth: 0 },
    location: { state : { prev : false } },
    url: config.API.uri + "/api/retrieve/content/all"
  }

  protected apiURL;
  
  constructor(props: IPage) {
    super(props);
    // console.log(props);
    this.state = {
      prev: false,
      prevDepth: this.getPathDepth(props.location)
    };
  }

  getPathDepth(location) {
    if (location.pathname !== void 0) {
      let pathArr = (location || {}).pathname.split("/");
      pathArr = pathArr.filter(n => n !== "");
      return pathArr.length;
    }

    return 0;
  }

  componentWillReceiveProps() {
    this.setState({ prevDepth: this.getPathDepth(this.props.location) });
  }

  render() {
      let cx = classNames({
        page: true,
        'page--prev': false,
        // 'page--prev': this.props.location && this.props.location.state && this.props.location.state.prev,
        // 'page--prev': depth,
        // 'page--prev': !(history && history.state && history.state.state.prev),
      });
      const { color, classes, background, chidlrenClasses, children} = this.props;
                // let state = location !== void 0 && location.state !== void 0 ? location.state : false;
                // let depth = this.getPathDepth(location) - this.state.prevDepth >= 0;

      cx += " " + classes;
      
      return (
        <section 
          className={cx}
          style={{
            color,
            background,
          }}
        >
          <section className={chidlrenClasses}>
            {children}
          </section>
        </section>
      );
  }
}