import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Dictionnary from "./Helper/Dictionnary";
import * as localforage from 'localforage';
import UrlParser from "./Helper/UrlParser";

const dictionnary = new Dictionnary(["fr","de","en","nl"]);
const URI = new UrlParser(window.location.href);
const URIParams = URI.getAllUrlParams();

if (URIParams["language"] !== void 0 && dictionnary.hasLocale(URIParams["language"])) {
    dictionnary.setLocale(URIParams["language"]);
    localforage.setItem("language", URIParams["language"])
    .then(() => { launchApp(); }).catch(err => { launchApp() });
} else {
    localforage.getItem('language').then(function(value) {
        if(value !== null) {
            dictionnary.setLocale(value);
        } else {
            dictionnary.setLocale("fr");
        }
        launchApp();
    }).catch(function(err) {
        launchApp();
    });
}

function launchApp() {
    dictionnary.fillBook().then(result => {
        ReactDOM.render(<App dictionnary={dictionnary} />, document.getElementById('root'));
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
