import axios from "axios";
import config from "../config";
import * as localforage from 'localforage';

class Dictionnary {

    protected locale = null;
    protected locales = ["fr"];
    protected prefix = "Api:App.";
    protected book:any = {};
    protected tryConnecting = 0;
    protected maxTryConnection = 30;

    constructor(locales) {
        this.locales = locales;
    }

    getBook() {
        return this.book;
    }

    setLocale(locale) {
        this.locale = locale;
    }

    hasLocale(locale) {
        return this.locales.includes(locale);
    }

    getLocale() {
        return this.locale;
    }

    getLocales() {
        return this.locales;
    }

    isReady() {
        // Add fallback with xx tentatives for retrieving content
        this.tryConnecting++;
        if (this.tryConnecting === this.maxTryConnection)
            return true;
        return Object.entries(this.book).length > 0;
    }

    async fetchOnline(store)
    {
        const response = await axios.get(config.API.uri + "/api/get/messages", {
            params: {
                locale: this.locale
            }
        })

        if(response.status === 200) {
            this.book = response.data;
            if (store === true) {
                localforage.setItem("book-"+this.locale, response.data);
            }
        }
    }

    async fillBook() {
        const value = await localforage.getItem("book-"+this.locale);
        if(value === null || config.env === "dev") {
            this.fetchOnline(true);
        } else {
            this.book = value;
        }
    }

    addTranslation(slug) {
        axios.get(config.API.uri + "/api/get/message", {
            params: {
                locale: this.locale,
                key: (this.prefix+slug)
            }
        }).then(result => {
            return (result.data["message"]);
        }).catch(error => {});
    }

    getTranslation(slug) {
        if (config.env === "dev") {
            this.addTranslation(slug)
        }
        if (this.book[this.prefix+slug]) {
            return this.book[this.prefix+slug];
        }
        return slug;
    }
}

export default Dictionnary;
